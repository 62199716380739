import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import * as style from './UserItem.module.scss'
import RouteURL from '~routes'

const UserItem = (props) => {
  const { className, name, userRole, imgUrl, id, ...rest } = props

  return (
    <div {...rest} className={classNames(style.userItem, className)}>
      <Link to={`${RouteURL.PROFILE}${id}`} className={style.imgWrapper}>
        <img
          src={imgUrl}
          alt={name}
          width={48}
          height={48}
          className={style.img}
        />
      </Link>
      <p className={classNames('text-syne-13-semi-bold', style.textRole)}>
        {userRole}
      </p>
      <Link
        to={`${RouteURL.PROFILE}${id}`}
        className={classNames('text-joyride-15', style.textName)}
      >
        {name}
      </Link>
    </div>
  )
}

UserItem.defaultProps = {
  className: '',
}

UserItem.propTypes = {
  id: PropTypes.number.isRequired,
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  userRole: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
}

export default UserItem
