import React, { useState } from 'react'
import { Container, Nav } from 'react-bootstrap'
import classNames from 'classnames'

import SEO from '~components/SEO'
import CardNFT from '~components/CardNFT'
import PersonalSocialLinks from '~components/PersonalSocialLinks'

import { AuctionStatus } from '~utils/enums'
import { MOCK_ATHLETE_DATA, MOCK_NFTS } from './mocks'

import * as style from './Legend.module.scss'

/**
 * Legend's page
 */
const Legend = () => {
  const [activeTab, setActiveTab] = useState(AuctionStatus.ONSALE)

  const filteredItems = (s) => MOCK_NFTS.filter(({ status }) => status === s)

  return (
    <>
      <SEO title={MOCK_ATHLETE_DATA.name} description={MOCK_ATHLETE_DATA.bio} />
      <section className={style.legend}>
        <Container>
          <div className={style.legendHero}>
            <div className={style.legendPhoto}>
              <div className={style.legendPhotoWrapper}>
                <img
                  src={MOCK_ATHLETE_DATA.photo}
                  alt={MOCK_ATHLETE_DATA.name}
                />
              </div>
            </div>
            <div className={style.legendInfo}>
              <h1>{MOCK_ATHLETE_DATA.name}</h1>
              <p>{MOCK_ATHLETE_DATA.bio}</p>
            </div>
            <PersonalSocialLinks socialLinks={MOCK_ATHLETE_DATA.socials} />
          </div>
          <Nav variant="white">
            <Nav.Item>
              <Nav.Link
                as="button"
                onClick={() => setActiveTab(AuctionStatus.ONSALE)}
                active={activeTab === AuctionStatus.ONSALE}
              >
                On sale
                <small>{filteredItems(AuctionStatus.ONSALE).length}</small>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                as="button"
                active={activeTab === AuctionStatus.SOLD}
                onClick={() => setActiveTab(AuctionStatus.SOLD)}
              >
                Sold
                <small>{filteredItems(AuctionStatus.SOLD).length}</small>
              </Nav.Link>
            </Nav.Item>
          </Nav>
          <div className={classNames('grid-nfts', style.items)}>
            {filteredItems(activeTab).map((nftData) => (
              <CardNFT key={nftData.id} {...nftData} />
            ))}
          </div>
        </Container>
      </section>
    </>
  )
}

export default Legend
