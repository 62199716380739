import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import RouteURL from '~routes'

import * as style from './CardNFT.module.scss'

const CardNFT = (props) => {
  const { className, athlete, id, imgUrl, nftId, owner, price, ...rest } = props

  return (
    <Link
      {...rest}
      className={classNames(style.cardNft, className)}
      to={`${RouteURL.NFT}${id}`}
    >
      <span className={style.imgAspectRatioContainer}>
        <img
          alt={`${athlete.firstName} ${athlete.lastName} #${nftId}`}
          src={imgUrl}
          className={style.imgNft}
        />
        <span className={style.imgOverlay}>
          <img
            src={owner.imgUrl}
            alt={owner.username}
            width={32}
            height={32}
            className={style.imgOwner}
          />
          <span className={classNames('text-syne-13-bold', style.badgeNftId)}>
            #{nftId}
          </span>
        </span>
      </span>
      <span className={style.gridAthlete}>
        <img
          width={24}
          height={24}
          alt={`${athlete.firstName} ${athlete.lastName}`}
          src={athlete.imgUrl}
          className={style.imgAthlete}
        />
        <h2 className={classNames('text-joyride-12', style.textAthleteName)}>
          {athlete.firstName} {athlete.lastName}
        </h2>
      </span>
      <span className={style.btnBuy}>
        <span
          className={classNames('text-syne-13-semi-bold', style.textBuyNow)}
        >
          Buy Now
        </span>
        <span className={classNames('text-joyride-15', style.textPrice)}>
          {price} SOL
        </span>
      </span>
    </Link>
  )
}

CardNFT.defaultProps = {
  className: '',
}

CardNFT.propTypes = {
  className: PropTypes.string,
  athlete: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imgUrl: PropTypes.string,
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  imgUrl: PropTypes.string.isRequired,
  nftId: PropTypes.number.isRequired,
  owner: PropTypes.shape({
    imgUrl: PropTypes.string,
    username: PropTypes.string,
  }).isRequired,
  price: PropTypes.number.isRequired,
}

export default CardNFT
