import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Nav, TabContainer, TabContent, TabPane } from 'react-bootstrap'

import * as style from './MintNFT.module.scss'
import BlockFormCreate from './components/BlockForm/BlockFormCreate'
import BlockFormAdd from './components/BlockForm/BlockFormAdd'

const MintNFT = (props) => {
  const { className, ...rest } = props

  const defaultActiveTabKey = 'create'
  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTabKey)

  const handleTabSelect = (key) => setActiveTabKey(key)

  return (
    <section {...rest} className={classNames(style.mintNft, className)}>
      <h1>Mint NFT</h1>
      <TabContainer
        defaultActiveKey={defaultActiveTabKey}
        activeKey={activeTabKey}
        onSelect={handleTabSelect}
      >
        <Nav variant="white" defaultActiveKey="create" className={style.nav}>
          <Nav.Item>
            <Nav.Link eventKey="create" as="button">
              Create new
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="add" as="button">
              Add existing
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <TabContent className={style.tabContent}>
          <TabPane eventKey="create">
            <BlockFormCreate />
          </TabPane>
          <TabPane eventKey="add">
            <BlockFormAdd />
          </TabPane>
        </TabContent>
      </TabContainer>
    </section>
  )
}

MintNFT.defaultProps = {
  className: '',
}

MintNFT.propTypes = {
  className: PropTypes.string,
}

export default MintNFT
