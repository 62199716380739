export const AuctionHistoryItemType = {
  MINT: 'MINT',
  PURCHASE: 'PURCHASE',
}

export const AuctionStatus = {
  ONSALE: 'on_sale',
  SOLD: 'sold',
  OWNED: 'owned',
}

export const SearchItemsType = {
  LEGENDS: 'Legends',
  ITEMS: 'Items',
  USERS: 'Users',
}
