export const MOCK_LEGENDS = [
  {
    id: 1,
    firstName: 'Michael',
    lastName: 'Bisping',
    nftCount: 743,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 2,
    firstName: 'GIORGIO',
    lastName: 'CHIELLINI',
    nftCount: 345,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 3,
    firstName: 'TERENCE',
    lastName: 'CRAWFORD',
    nftCount: 389,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 4,
    firstName: 'CHARLIE',
    lastName: 'EDWARDS',
    nftCount: 481,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 5,
    firstName: 'CHUCK',
    lastName: 'LIDDELL',
    nftCount: 104,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 6,
    firstName: 'Michael',
    lastName: 'Bisping',
    nftCount: 425,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 7,
    firstName: 'GIORGIO',
    lastName: 'CHIELLINI',
    nftCount: 345,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 8,
    firstName: 'TERENCE',
    lastName: 'CRAWFORD',
    nftCount: 453,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 9,
    firstName: 'CHARLIE',
    lastName: 'EDWARDS',
    nftCount: 528,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 10,
    firstName: 'CHUCK',
    lastName: 'LIDDELL',
    nftCount: 104,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 11,
    firstName: 'Michael',
    lastName: 'Bisping',
    nftCount: 743,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 12,
    firstName: 'GIORGIO',
    lastName: 'CHIELLINI',
    nftCount: 345,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 13,
    firstName: 'TERENCE',
    lastName: 'CRAWFORD',
    nftCount: 789,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 14,
    firstName: 'CHARLIE',
    lastName: 'EDWARDS',
    nftCount: 145,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 15,
    firstName: 'CHUCK',
    lastName: 'LIDDELL',
    nftCount: 453,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
]
