import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Overlay, Popover } from 'react-bootstrap'
import classNames from 'classnames'

import * as style from './DropdownFilter.module.scss'
import DropdownToggle from '../DropdownToggle'

const DropdownFilter = (props) => {
  const {
    className,
    filterName,
    options,
    appendToFilter,
    clearFromFilter,
    ...rest
  } = props

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const showOverlay = () => setIsOverlayVisible(true)
  const hideOverlay = () => setIsOverlayVisible(false)

  const toggleRef = useRef()

  return (
    <>
      <DropdownToggle
        {...rest}
        text={filterName}
        ref={toggleRef}
        onToggle={showOverlay}
        isExpanded={isOverlayVisible}
      />
      <Overlay
        target={toggleRef.current}
        placement="bottom-start"
        show={isOverlayVisible}
        onHide={hideOverlay}
        rootClose
        trigger="click"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        }}
      >
        {({ placement, arrowProps, show: _show, popper, ...overlayProps }) => (
          <Popover
            id="search-popover"
            className={style.popover}
            {...overlayProps}
          >
            <div className={style.popoverInner}>
              <ul className={style.listOptions}>
                {options.map((option) => (
                  <li className={style.itemOption} key={option.value}>
                    <button
                      type="button"
                      onClick={() => {
                        if (option.isActive) {
                          clearFromFilter(filterName, option)
                        } else {
                          appendToFilter(filterName, option)
                        }
                        setIsOverlayVisible(false)
                      }}
                      className={classNames(style.itemOptionButton, {
                        [style.active]: option.isActive,
                      })}
                    >
                      <span>{option.label}</span>
                      <span
                        className={classNames(
                          'text-syne-12-bold',
                          style.textQuantity
                        )}
                      >
                        {option.quantity}
                      </span>
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </Popover>
        )}
      </Overlay>
    </>
  )
}

DropdownFilter.defaultProps = {
  className: '',
}

DropdownFilter.propTypes = {
  className: PropTypes.string,
  filterName: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
      quantity: PropTypes.number,
    })
  ).isRequired,
  appendToFilter: PropTypes.func.isRequired,
  clearFromFilter: PropTypes.func.isRequired,
}

export default DropdownFilter
