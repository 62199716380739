export const MOCK_LEGENDS = [
  {
    id: 1,
    firstName: 'Michael',
    lastName: 'Bisping',
    nftCount: 743,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 2,
    firstName: 'GIORGIO',
    lastName: 'CHIELLINI',
    nftCount: 345,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 3,
    firstName: 'TERENCE',
    lastName: 'CRAWFORD',
    nftCount: 389,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 4,
    firstName: 'CHARLIE',
    lastName: 'EDWARDS',
    nftCount: 528,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
  {
    id: 5,
    firstName: 'CHUCK',
    lastName: 'LIDDELL',
    nftCount: 104,
    imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
  },
]

export const MOCK_NFTS = [
  {
    id: 1,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Wayne',
      lastName: 'Rooney',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 5751,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 3.49,
  },
  {
    id: 2,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Muhammad',
      lastName: 'Ali',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 8180,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 2.51,
  },
  {
    id: 3,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Alexander',
      lastName: 'Ovechkin',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 6370,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 2.39,
  },
  {
    id: 4,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Michael',
      lastName: 'Bisping',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 2485,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 5.8,
  },
]

export const MOCK_NFTS_12 = Array(3)
  .fill(MOCK_NFTS)
  .flat()
  .map((item, index) => ({ ...item, id: index + 1 }))

export const MOCK_HERO_NFT = {
  id: 0,
  title: 'Wayne Rooney #5751',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo tellus pretium consectetur morbi diam nullam eu. Sed volutpat pharetra consectetur scelerisque viverra gravida magna enim.',
  imgUrl: `https://picsum.photos/1200/1200?nocache=${Math.random()}`,
  priceSOL: 3.49,
  priceUSDT: 0.86,
  athlete: {
    id: 1,
    name: 'Wayne Rooney',
    imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
}

export const EXPLORE_FILTERS = [
  {
    filterName: 'accessory',
    options: [
      { value: 'crownCall', label: 'Crown Call', quantity: 10 },
      { value: 'bloodyGlove', label: 'Bloody Glove', quantity: 7 },
      { value: 'eggBall', label: 'Egg Ball', quantity: 10 },
      { value: 'gloves', label: 'Gloves', quantity: 12 },
      { value: 'solGloves', label: 'SOL Gloves', quantity: 6 },
      { value: 'pigskinBall', label: 'Pigskin Ball', quantity: 16 },
      { value: 'goldGlove', label: 'Gold Glove', quantity: 11 },
      { value: 'bloodyWraps', label: 'Bloody Wraps', quantity: 10 },
      { value: 'yellowCard', label: 'Yellow Card', quantity: 6 },
      { value: 'noTrait', label: 'No trait', quantity: 65 },
    ],
  },
  {
    filterName: 'background',
    options: [
      { value: 'money', label: 'Money', quantity: 10 },
      { value: 'bats', label: 'Bats', quantity: 18 },
      { value: 'confetti', label: 'Confetti', quantity: 3 },
      { value: 'dark', label: 'Dark', quantity: 30 },
    ],
  },
  {
    filterName: 'base',
    options: [{ value: 'defaultBase', label: 'Default Base', quantity: 10 }],
  },
  {
    filterName: 'clothing',
    options: [
      { value: 'defaultClothing', label: 'Default Clothing', quantity: 10 },
    ],
  },
  {
    filterName: 'edition',
    options: [
      { value: 'defaultEdition', label: 'Default Edition', quantity: 10 },
    ],
  },
  {
    filterName: 'eyes',
    options: [{ value: 'defaultEyes', label: 'Default Eyes', quantity: 10 }],
  },
  {
    filterName: 'face',
    options: [{ value: 'defaultFace', label: 'Default Face', quantity: 10 }],
  },
  {
    filterName: 'head',
    options: [{ value: 'defaultHead', label: 'Default Head', quantity: 10 }],
  },
  {
    filterName: 'mouth',
    options: [
      { value: 'defaultLegend', label: 'Default Legend', quantity: 10 },
    ],
  },
]
