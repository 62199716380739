import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDropzone } from 'react-dropzone'
import { Button } from 'react-bootstrap'

import * as style from './Dropzone.module.scss'

/**
 * Dropzone component
 */
const Dropzone = (props) => {
  const {
    className,
    onDrop,
    text,
    hint,
    name,
    id,
    dropzoneOptions,
    onDropRejected,
    ...rest
  } = props

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    ...dropzoneOptions,
    onDrop,
    onDropRejected,
  })

  return (
    <div
      {...rest}
      {...getRootProps()}
      className={classNames(
        style.dropzone,
        { [style.dragActive]: isDragActive },
        className
      )}
    >
      <input {...getInputProps()} id={id} />
      {isDragActive ? (
        <>
          <p className={classNames('text-syne-13-semi-bold', style.textMain)}>
            Drop the image here ...
          </p>
          <div className={style.btnWrapper}>
            <Button variant="tertiary">Drop here</Button>
          </div>
        </>
      ) : (
        <>
          <p className={classNames('text-syne-13-semi-bold', style.textMain)}>
            {text}
          </p>
          <div className={style.btnWrapper}>
            <Button variant="tertiary">Choose File</Button>
          </div>
        </>
      )}
    </div>
  )
}

Dropzone.defaultProps = {
  className: '',
  hint: '',
}

Dropzone.propTypes = {
  className: PropTypes.string,
  onDrop: PropTypes.func.isRequired,
  onDropRejected: PropTypes.func.isRequired,
  text: PropTypes.any.isRequired,
  hint: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  dropzoneOptions: PropTypes.object.isRequired,
}

export default Dropzone
