import React, { Suspense, useRef, useState } from 'react'
import { Button, Container } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'

import * as style from './Header.module.scss'
import useAuth from '~hooks/useAuth'
import RouteURL from '~routes'
import BlockassetLogo from '~components/BlockassetLogo'
import InputWithLabel from '~components/InputWithLabel'
import InputSearchExpandable from '~components/InputSearchExpandable'
import LinkAvatar from './components/LinkAvatar'
import NavMenuMobile from './components/NavMenuMobile'

const ModalConnectWallet = React.lazy(() =>
  import(
    /* webpackChunkName: "modal-connect-wallet" */ './components/ModalConnectWallet'
  )
)

const Header = () => {
  const { isLoggedIn } = useAuth()

  const [isModalConnectWalletVisible, setIsModalConnectWalletVisible] =
    useState(false)

  const hideModalConnectWallet = () => setIsModalConnectWalletVisible(false)
  const showModalConnectWallet = () => setIsModalConnectWalletVisible(true)

  const navigate = useNavigate()
  const searchInputRef = useRef()

  const [searchQuery, setSearchQuery] = useState('')

  return (
    <header className={style.headerWrapper}>
      <Suspense fallback={<div />}>
        <ModalConnectWallet
          show={isModalConnectWalletVisible}
          onHide={hideModalConnectWallet}
        />
      </Suspense>
      <Container className={style.header}>
        <form
          onSubmit={(e) => {
            e.preventDefault()
            navigate(RouteURL.SEARCH)
            searchInputRef.current?.blur()
          }}
          className={style.formSearch}
        >
          <InputWithLabel
            placeholder="Search"
            variant="search"
            ref={searchInputRef}
            className={style.inpSearchDesktop}
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <InputSearchExpandable
            className={style.inpSearchExpandable}
            value={searchQuery}
            setValue={setSearchQuery}
          />
          <input type="submit" style={{ display: 'none' }} />
        </form>
        <BlockassetLogo />
        <div className={style.gridHeaderRight}>
          <div className={style.btnWrapper}>
            {isLoggedIn ? (
              <Button
                title="Create NFT"
                variant="neon"
                as={Link}
                to={RouteURL.MINT_NFT}
              >
                Create
              </Button>
            ) : (
              <Button
                title="Connect wallet"
                variant="neon"
                onClick={showModalConnectWallet}
              >
                Connect<span className={style.textWallet}>&nbsp;Wallet</span>
              </Button>
            )}
          </div>
          {isLoggedIn && <LinkAvatar />}
        </div>
        <NavMenuMobile
          className={style.btnMenu}
          onClickConnectWallet={showModalConnectWallet}
        />
      </Container>
    </header>
  )
}

export default Header
