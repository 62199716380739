import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './BlockForm.module.scss'
import InputWithLabel from '~components/InputWithLabel'
import InputSelect from '~components/InputSelect'
import { MOCK_NETWORK_OPTIONS } from '../mocks'
import { maskNumberValue } from '~utils'
import CardExtraPriceInfo from '../CardExtraPriceInfo'

const BlockFormAdd = (props) => {
  const { className, ...rest } = props

  const methods = useForm()

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = methods

  const onSubmit = (formValues) => console.log(formValues)

  const [price] = watch(['price'])

  return (
    <form
      {...rest}
      className={classNames(style.blockForm, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <InputWithLabel
          label="NFT ID"
          placeholder="Enter NFT ID"
          {...register('nftId', {
            required: 'Please, specify the NFT ID',
          })}
          errors={errors}
        />
        <InputWithLabel
          label="Contract address"
          placeholder="Enter NFT contract address"
          {...register('contractAddress', {
            required: 'Please, specify the NFT contract address',
          })}
          errors={errors}
        />
        <div>
          <Controller
            name="price"
            control={control}
            rules={{
              required: 'Please, specify the price',
              min: {
                value: 0.000000000000000001,
                message: 'The price is too small',
              },
            }}
            render={({ field: { onChange, ...fieldProps } }) => (
              <InputWithLabel
                {...fieldProps}
                id="inp-nft-price"
                label="Price"
                placeholder="Enter price for this NFT"
                inputMode="decimal"
                errors={errors}
                onChange={(e) => onChange(maskNumberValue(e.target.value))}
                autoComplete="off"
                variant="sol"
              />
            )}
          />
          <CardExtraPriceInfo price={price} serviceFee={2.5} />
        </div>
        <InputSelect
          label="Network"
          name="network"
          placeholder="Select network"
          options={MOCK_NETWORK_OPTIONS}
          msgRequired="Please, specify the network"
          errors={errors}
          required
        />
        <Button type="submit" variant="purple" className={style.btnCreate}>
          ADD NFT
        </Button>
      </FormProvider>
      <DevTool control={control} />
    </form>
  )
}

BlockFormAdd.defaultProps = {
  className: '',
}

BlockFormAdd.propTypes = {
  className: PropTypes.string,
}

export default BlockFormAdd
