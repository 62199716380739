import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FormProvider, useForm } from 'react-hook-form'
import classNames from 'classnames'

import * as style from '../../Homepage.module.scss'
import InputSelect from '~components/InputSelect'
import { EXPLORE_SORT_OPTIONS } from './constants'
import { EXPLORE_FILTERS, MOCK_NFTS_12 } from '../../mocks'
import CardNFT from '~components/CardNFT'
import Paginator from '~components/Paginator'
import DropdownFilter from '../DropdownFilter'
import DropdownPriceFilter from '../DropdownPriceFilter'
import BadgeActiveFilter from '../BadgeActiveFilter'

const SectionExplore = (props) => {
  const { className, ...rest } = props

  const methods = useForm({
    defaultValues: { sortOrder: [EXPLORE_SORT_OPTIONS[0]] },
  })
  const [availableFilters, setAvailableFilters] = useState(EXPLORE_FILTERS)
  const [activeFilters, setActiveFilters] = useState({})
  const [minPrice, setMinPrice] = useState()
  const [maxPrice, setMaxPrice] = useState()

  const clearPriceFilter = () => {
    setMinPrice(undefined)
    setMaxPrice(undefined)
  }

  const areFiltersDefined = (
    activeFiltersObject,
    minPriceValue,
    maxPriceValue
  ) => {
    if (minPriceValue != null || maxPriceValue != null) return true

    return (
      activeFiltersObject &&
      Object.values(activeFiltersObject).flat().length > 0
    )
  }

  const appendToFilter = (filterName, filterValueObject) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: [...(prevFilters?.[filterName] ?? []), filterValueObject],
    }))

    setAvailableFilters((prevFilters) => {
      const tempFilters = [...prevFilters]

      const affectedFilterIndex = tempFilters.findIndex(
        (filter) => filter.filterName === filterName
      )

      const affectedOptionIndex = tempFilters[
        affectedFilterIndex
      ].options.findIndex((option) => option.value === filterValueObject.value)

      tempFilters[affectedFilterIndex].options[
        affectedOptionIndex
      ].isActive = true

      return tempFilters
    })
  }

  const clearFromFilter = (filterName, filterValueObject) => {
    setActiveFilters((prevFilters) => ({
      ...prevFilters,
      [filterName]: prevFilters[filterName].filter(
        (filter) => filter.value !== filterValueObject.value
      ),
    }))

    setAvailableFilters((prevFilters) => {
      const tempFilters = [...prevFilters]

      const affectedFilterIndex = tempFilters.findIndex(
        (filter) => filter.filterName === filterName
      )

      const affectedOptionIndex = tempFilters[
        affectedFilterIndex
      ].options.findIndex((option) => option.value === filterValueObject.value)

      tempFilters[affectedFilterIndex].options[
        affectedOptionIndex
      ].isActive = false

      return tempFilters
    })
  }

  return (
    <section {...rest} className={style.sectionExplore}>
      <div className={style.rowHeading}>
        <FormProvider {...methods}>
          <h1>Explore</h1>
          <InputSelect
            name="sortOrder"
            options={EXPLORE_SORT_OPTIONS}
            isSearchable={false}
            className={style.inpSelectSort}
          />
        </FormProvider>
      </div>
      <div className={style.outerWrapperFilters}>
        <div className={style.scrollContainerFilters}>
          <div className={style.rowExploreFilters}>
            <DropdownPriceFilter
              minPrice={minPrice}
              maxPrice={maxPrice}
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
            />
            {availableFilters.map((exploreFilter) => (
              <DropdownFilter
                key={exploreFilter.filterName}
                {...exploreFilter}
                appendToFilter={appendToFilter}
                clearFromFilter={clearFromFilter}
              />
            ))}
          </div>
        </div>
        <div className={style.blendShadowFiltersStart} />
        <div className={style.blendShadowFiltersEnd} />
      </div>
      {areFiltersDefined(activeFilters, minPrice, maxPrice) && (
        <div className={style.outerWrapperFilters}>
          <div className={style.scrollContainerFilters}>
            <div className={style.rowActiveFilters}>
              {(minPrice != null || maxPrice != null) && (
                <BadgeActiveFilter
                  text={`${minPrice || 0} SOL - ${maxPrice || '∞'} SOL`}
                  onClear={clearPriceFilter}
                />
              )}
              {Object.entries(activeFilters).map(
                ([filterName, filterObjectValuesArray]) =>
                  filterObjectValuesArray.map((filterObjectValue) => (
                    <BadgeActiveFilter
                      text={filterObjectValue.label}
                      onClear={() =>
                        clearFromFilter(filterName, filterObjectValue)
                      }
                    />
                  ))
              )}
            </div>
          </div>
          <div className={style.blendShadowFiltersStart} />
          <div className={style.blendShadowFiltersEnd} />
        </div>
      )}
      <div className={classNames('grid-nfts', style.gridExplore)}>
        {MOCK_NFTS_12.map((nftData) => (
          <CardNFT key={nftData.id} {...nftData} />
        ))}
      </div>
      <Paginator className={style.paginator} pageCount={20} />
    </section>
  )
}

SectionExplore.defaultProps = {
  className: '',
}

SectionExplore.propTypes = {
  className: PropTypes.string,
}

export default SectionExplore
