import React from 'react'
import { Container } from 'react-bootstrap'

import BlockNFT from '~components/BlockNFT'
import SEO from '~components/SEO'

import { MOCK_NFT_DATA } from './mocks'

/**
 * NFT page
 */
const NFT = () => {
  return (
    <Container>
      <SEO
        title={MOCK_NFT_DATA.title}
        description={MOCK_NFT_DATA.description}
      />
      <BlockNFT {...MOCK_NFT_DATA} variant="nftPage" />
    </Container>
  )
}

export default NFT
