// extracted by mini-css-extract-plugin
export var blockNft = "BlockNFT_blockNft__7p74O";
export var homepage = "BlockNFT_homepage__7UrMt";
export var gridPurchase = "BlockNFT_gridPurchase__EBxfM";
export var gridAthlete = "BlockNFT_gridAthlete__YCxBU";
export var imgAspectRatioContainer = "BlockNFT_imgAspectRatioContainer__a3Cmm";
export var img = "BlockNFT_img__39NHB";
export var imgAthlete = "BlockNFT_imgAthlete__egDTS";
export var textNameAthlete = "BlockNFT_textNameAthlete__1AEuW";
export var title = "BlockNFT_title__Cg6Bf";
export var gridPrices = "BlockNFT_gridPrices__nbThI";
export var textPricePrimary = "BlockNFT_textPricePrimary__uCiWE";
export var textPriceSecondary = "BlockNFT_textPriceSecondary__koRrZ";
export var btnBuyNow = "BlockNFT_btnBuyNow__aj4pu";
export var tabsBlock = "BlockNFT_tabsBlock__7O3AQ";
export var badgeSold = "BlockNFT_badgeSold__GAd6N";
export var rotate = "BlockNFT_rotate__cAEv6";
export var dotty = "BlockNFT_dotty__BDmZZ";