// extracted by mini-css-extract-plugin
export var search = "Search_search__rpivZ";
export var textSearchQuery = "Search_textSearchQuery__OrYl5";
export var textPageHeading = "Search_textPageHeading__Oj4ws";
export var navContainerOuter = "Search_navContainerOuter__Dh8da";
export var navScrollContainer = "Search_navScrollContainer__tA2Cz";
export var nav = "Search_nav__FIZno";
export var blendShadowEnd = "Search_blendShadowEnd__SbaNI";
export var blendShadowStart = "Search_blendShadowStart__ldqM4";
export var wrapperItems = "Search_wrapperItems__8Qqax";
export var paginator = "Search_paginator__-8J+o";
export var rotate = "Search_rotate__jxmG8";
export var dotty = "Search_dotty__-HKII";