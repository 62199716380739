import React, { useEffect } from 'react'
import Select from 'react-select'

import './InputSelect.scss'
import { FormLabel, FormText } from 'react-bootstrap'
import { useFormContext } from 'react-hook-form'
import PropTypes from 'prop-types'

const InputSelect = (props) => {
  const {
    className,
    options,
    placeholder,
    label,
    id,
    errors,
    name,
    required,
    msgRequired,
    ...rest
  } = props

  const { register, unregister, setValue, clearErrors, watch } =
    useFormContext()

  useEffect(() => {
    register(name, { required: required ? msgRequired : false })
    return () => unregister(name)
  }, [register, unregister, name, required, msgRequired])

  const handleChange = (newValue) => {
    setValue(name, newValue)
    clearErrors(name)
  }

  const values = watch([name])

  return (
    <div className={className}>
      {!!label && <FormLabel htmlFor={id}>{label}</FormLabel>}
      <Select
        {...rest}
        id={id}
        options={options}
        classNamePrefix="input-select"
        className="input-select-container"
        components={{ IndicatorSeparator: null }}
        placeholder={placeholder}
        onChange={(newValue) => handleChange(newValue)}
        value={values?.[0] ?? ''}
      />
      {!!errors?.[name] && (
        <FormText className="form-text--error">{errors[name].message}</FormText>
      )}
    </div>
  )
}

InputSelect.defaultProps = {
  className: '',
  placeholder: undefined,
  label: '',
  errors: null,
  required: false,
  msgRequired: 'This value is required',
  id: null,
}

InputSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({ label: PropTypes.string, value: PropTypes.any })
  ).isRequired,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  id: PropTypes.string,
  errors: PropTypes.any,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  msgRequired: PropTypes.string,
}

export default InputSelect
