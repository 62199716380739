import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Nav } from 'react-bootstrap'
import { Link, useParams } from 'react-router-dom'

import * as style from './Profile.module.scss'
import { MOCK_PROFILE, MOCK_NFTS } from './mocks'
import { AuctionStatus } from '~utils/enums'
import Icon from '~components/Icon'
import InputCopy from '~components/InputCopy'
import CardNFT from '~components/CardNFT'
import Paginator from '~components/Paginator'
import PersonalSocialLinks from '~components/PersonalSocialLinks'
import RouteURL from '~routes'

const Profile = (props) => {
  const { className, ...rest } = props

  const [activeTab, setActiveTab] = useState(AuctionStatus.ONSALE)

  const filteredItems = (s) => MOCK_NFTS.filter(({ status }) => status === s)

  const { id } = useParams()

  const isOwnProfile = id === '1'

  return (
    <section {...rest} className={classNames(style.profile, className)}>
      <div className={style.coverImgContainer}>
        <img
          src={MOCK_PROFILE.coverImgUrl}
          alt="cover"
          className={style.imgCover}
        />
      </div>
      <Container className={style.container}>
        <div className={style.gridAccountInfo}>
          <img
            src={MOCK_PROFILE.imgUrl}
            width={200}
            height={200}
            alt="avatar"
            className={style.imgAvatar}
          />
          <div className={style.containerTextName}>
            <h1 className={style.textName}>{MOCK_PROFILE.name}</h1>
            {isOwnProfile && (
              <Link
                to={RouteURL.EDIT_PROFILE}
                className={classNames(
                  'text-syne-14-bold',
                  style.btnEditProfile
                )}
              >
                <Icon name="icon-pen" size={16} />
                <span>Edit profile</span>
              </Link>
            )}
          </div>
          <InputCopy
            value="0x9486371gd44js56d4k692dfk30dl0bC618148sC238"
            className={classNames(style.inpCopy, {
              [style.extraMargin]: isOwnProfile,
            })}
          />
          <PersonalSocialLinks socialLinks={MOCK_PROFILE.socials} />
        </div>
        <div className={style.navContainerOuter}>
          <div className={style.navScrollContainer}>
            <Nav
              variant="white"
              onSelect={setActiveTab}
              activeKey={activeTab}
              className={style.nav}
            >
              <Nav.Item>
                <Nav.Link as="button" eventKey={AuctionStatus.ONSALE}>
                  On sale
                  <small>{filteredItems(AuctionStatus.ONSALE).length}</small>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as="button" eventKey={AuctionStatus.SOLD}>
                  Sold
                  <small>{filteredItems(AuctionStatus.SOLD).length}</small>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as="button" eventKey={AuctionStatus.OWNED}>
                  Owned
                  <small>{filteredItems(AuctionStatus.OWNED).length}</small>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className={style.blendShadowStart} />
          <div className={style.blendShadowEnd} />
        </div>
        <div className={classNames('grid-nfts', style.items)}>
          {filteredItems(activeTab).map((nftData) => (
            <CardNFT key={nftData.id} {...nftData} />
          ))}
        </div>
        {filteredItems(activeTab).length > 20 && (
          <Paginator pageCount={20} className={style.paginator} />
        )}
      </Container>
    </section>
  )
}

Profile.defaultProps = {
  className: '',
}

Profile.propTypes = {
  className: PropTypes.string,
}

export default Profile
