// extracted by mini-css-extract-plugin
export var textDescription = "TabsBlock_textDescription__DqRbi";
export var tabDetailsContent = "TabsBlock_tabDetailsContent__95rrq";
export var tabHistoryContent = "TabsBlock_tabHistoryContent__jdAp5";
export var isLoading = "TabsBlock_isLoading__zugud";
export var cardCollection = "TabsBlock_cardCollection__r+8rp";
export var gridRelatedUsers = "TabsBlock_gridRelatedUsers__A1zTG";
export var gridHistoryItems = "TabsBlock_gridHistoryItems__v4-r7";
export var spinner = "TabsBlock_spinner__Q7DQS";
export var rotate = "TabsBlock_rotate__PDp4U";
export var dotty = "TabsBlock_dotty__AYT1e";