import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

const AuthContext = createContext({
  isLoggedIn: false,
  connect: () => {},
  disconnect: () => {},
})

// eslint-disable-next-line react/prop-types
export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false)

  const connect = useCallback(() => setIsLoggedIn(true), [])

  const disconnect = useCallback(() => setIsLoggedIn(false), [])

  const memoedValue = useMemo(
    () => ({
      isLoggedIn,
      connect,
      disconnect,
    }),
    [isLoggedIn, connect, disconnect]
  )

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  )
}

export default function useAuth() {
  return useContext(AuthContext)
}
