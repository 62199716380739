import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './BadgeActiveFilter.module.scss'
import Icon from '~components/Icon'

const BadgeActiveFilter = (props) => {
  const { className, text, onClear, ...rest } = props

  return (
    <div
      {...rest}
      className={classNames(
        'text-syne-13-semi-bold',
        style.badgeActiveFilter,
        className
      )}
    >
      <span>{text}</span>
      <button
        type="button"
        aria-label="remove filter"
        className={style.btnClose}
        onClick={onClear}
      >
        <Icon name="icon-filter-close" size={6} />
      </button>
    </div>
  )
}

BadgeActiveFilter.defaultProps = {
  className: '',
}

BadgeActiveFilter.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClear: PropTypes.func.isRequired,
}

export default BadgeActiveFilter
