import React, { Suspense, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import * as style from './BlockNFT.module.scss'
import TabsBlock from './components/TabsBlock'
import RouteURL from '~routes'

const ModalBuyNow = React.lazy(() =>
  import(/* webpackChunkName: "modal-buy-now" */ './components/ModalBuyNow')
)

const BlockNFT = (props) => {
  const {
    id,
    className,
    title,
    description,
    priceSOL,
    priceUSDT,
    athlete,
    imgUrl,
    owner,
    creator,
    history,
    isSold = false,
    variant,
    ...rest
  } = props

  const isNftPage = variant === 'nftPage'
  const isHomepage = variant === 'homepage'

  const [isModalBuyVisible, setIsModalBuyVisible] = useState(false)

  const hideModalBuy = () => setIsModalBuyVisible(false)
  const showModalBuy = () => setIsModalBuyVisible(true)

  return (
    <div
      {...rest}
      className={classNames(
        style.blockNft,
        { [style[variant]]: variant },
        className
      )}
    >
      {isNftPage && (
        <Suspense fallback={<div />}>
          <ModalBuyNow show={isModalBuyVisible} onHide={hideModalBuy} />
        </Suspense>
      )}
      <div>
        <div className={style.imgAspectRatioContainer}>
          <img src={imgUrl} alt={title} className={style.img} />
        </div>
      </div>
      <div>
        <Link
          to={`${RouteURL.LEGEND}${athlete.id}`}
          className={style.gridAthlete}
        >
          <img
            alt={athlete.name}
            src={athlete.imgUrl}
            className={style.imgAthlete}
          />
          <p className={classNames('text-joyride-12', style.textNameAthlete)}>
            {athlete.name}
          </p>
        </Link>
        <h1 className={style.title}>{title}</h1>
        <div className={style.gridPurchase}>
          <div className={style.gridPrices}>
            <h3
              className={classNames('text-joyride-20', style.textPricePrimary)}
            >
              {priceSOL}
              <span className="text-joyride-15"> SOL</span>
            </h3>
            <p
              className={classNames(
                'text-syne-13-bold',
                style.textPriceSecondary
              )}
            >
              {priceUSDT} USDT
            </p>
          </div>
          {isNftPage && (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {isSold ? (
                <div className={classNames('text-joyride-13', style.badgeSold)}>
                  SOLD
                </div>
              ) : (
                <Button
                  variant="purple"
                  className={style.btnBuyNow}
                  onClick={showModalBuy}
                >
                  Buy Now
                </Button>
              )}
            </>
          )}
          {isHomepage && (
            <Button variant="tertiary" as={Link} to={`${RouteURL.NFT}${id}`}>
              View Artwork
            </Button>
          )}
        </div>
        {isNftPage && (
          <TabsBlock
            className={style.tabsBlock}
            owner={owner}
            creator={creator}
            history={history}
            description={description}
          />
        )}
      </div>
    </div>
  )
}

BlockNFT.defaultProps = {
  className: '',
  isSold: false,
  variant: 'nftPage',
  owner: {},
  creator: {},
  history: [],
}

BlockNFT.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  priceSOL: PropTypes.number.isRequired,
  priceUSDT: PropTypes.number.isRequired,
  athlete: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    imgUrl: PropTypes.string,
    pageUrl: PropTypes.string,
  }).isRequired,
  imgUrl: PropTypes.string.isRequired,
  owner: PropTypes.object,
  creator: PropTypes.object,
  history: PropTypes.array,
  isSold: PropTypes.bool,
  variant: PropTypes.oneOf(['homepage', 'nftPage']),
}

export default BlockNFT
