import React from 'react'
import classNames from 'classnames'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import * as style from './HistoryItem.module.scss'
import { AuctionHistoryItemType } from '~utils/enums'
import RouteURL from '~routes'

const HistoryItem = (props) => {
  const { className, type, user, timestamp, price } = props

  const getDescriptionText = () => {
    switch (type) {
      case AuctionHistoryItemType.MINT:
        return (
          <>
            Minted by{' '}
            <Link
              to={`${RouteURL.PROFILE}${user.id}`}
              className={style.textUserLink}
            >
              @{user.name}
            </Link>
          </>
        )
      case AuctionHistoryItemType.PURCHASE:
        return (
          <>
            Purchased by{' '}
            <Link
              to={`${RouteURL.PROFILE}${user.id}`}
              className={style.textUserLink}
            >
              @{user.name}
            </Link>{' '}
            for <span className={style.textPrice}>{price} SOL</span>
          </>
        )
      default:
        return ''
    }
  }

  return (
    <div className={classNames(style.auctionHistoryItem, className)}>
      <Link to={`${RouteURL.PROFILE}${user.id}`} className={style.imgWrapper}>
        <img
          src={user.imgUrl}
          alt={user.name}
          className={style.img}
          width={48}
          height={48}
        />
      </Link>
      <p className={style.textDesc}>{getDescriptionText()}</p>
      {timestamp && (
        <p className={classNames('text-syne-13-semi-bold', style.textDate)}>
          {dayjs.unix(timestamp).format('MMMM D, YYYY [at] HH:mm')}
        </p>
      )}
    </div>
  )
}

HistoryItem.defaultProps = {
  className: '',
  price: 0,
}

HistoryItem.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    imgUrl: PropTypes.string,
  }).isRequired,
  timestamp: PropTypes.number.isRequired,
  price: PropTypes.number,
}

export default HistoryItem
