const RouteURL = {
  HOMEPAGE: '/',
  NFT: '/nft/',
  LEGENDS: '/legends/',
  LEGEND: '/legend/',
  MINT_NFT: '/mint/',
  EDIT_PROFILE: '/profile/edit/',
  PROFILE: '/profile/',
  SEARCH: '/search/',
}

export default RouteURL
