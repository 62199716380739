// extracted by mini-css-extract-plugin
export var profile = "Profile_profile__uI5LS";
export var coverImgContainer = "Profile_coverImgContainer__y10ig";
export var imgCover = "Profile_imgCover__hTFYK";
export var container = "Profile_container__vsq0b";
export var gridAccountInfo = "Profile_gridAccountInfo__Mga3n";
export var imgAvatar = "Profile_imgAvatar__YmkhG";
export var containerTextName = "Profile_containerTextName__T3+NT";
export var textName = "Profile_textName__GCf-O";
export var btnEditProfile = "Profile_btnEditProfile__P4-KE";
export var inpCopy = "Profile_inpCopy__W4ehr";
export var extraMargin = "Profile_extraMargin__cHwQa";
export var navContainerOuter = "Profile_navContainerOuter__H7VAj";
export var navScrollContainer = "Profile_navScrollContainer__t7pXI";
export var nav = "Profile_nav__x0DOI";
export var blendShadowEnd = "Profile_blendShadowEnd__jahFY";
export var blendShadowStart = "Profile_blendShadowStart__dpeSx";
export var items = "Profile_items__cppku";
export var paginator = "Profile_paginator__bmhEa";
export var rotate = "Profile_rotate__O6dYS";
export var dotty = "Profile_dotty__dAn5q";