import React, { useState } from 'react'
import {
  Nav,
  Spinner,
  TabContainer,
  TabContent,
  TabPane,
} from 'react-bootstrap'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import * as style from './TabsBlock.module.scss'
import HistoryItem from '../HistoryItem'
import UserItem from '../UserItem'

const TabsBlock = (props) => {
  const { className, description, owner, creator, history, ...rest } = props

  const defaultActiveTabKey = 'details'
  const [activeTabKey, setActiveTabKey] = useState(defaultActiveTabKey)

  const handleTabSelect = (key) => {
    if (!key) return

    setActiveTabKey(key)
  }

  const isHistoryLoading = false

  return (
    <div {...rest} className={className}>
      <TabContainer
        defaultActiveKey={defaultActiveTabKey}
        activeKey={activeTabKey}
        onSelect={handleTabSelect}
      >
        <Nav variant="white" defaultActiveKey="details">
          <Nav.Item>
            <Nav.Link eventKey="details" as="button">
              Details
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="history" as="button">
              History
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <TabContent>
          <TabPane eventKey="details">
            <div className={style.tabDetailsContent}>
              <p className={style.textDescription}>{description}</p>
              <div className={style.gridRelatedUsers}>
                <UserItem userRole="Creator" {...creator} />
                <UserItem userRole="Owner" {...owner} />
              </div>
            </div>
          </TabPane>
          <TabPane eventKey="history">
            <div
              className={classNames(style.tabHistoryContent, {
                [style.isLoading]: isHistoryLoading,
              })}
            >
              <div className={style.gridHistoryItems}>
                {!isHistoryLoading ? (
                  history?.map((historyItem) => (
                    <HistoryItem
                      // eslint-disable-next-line no-underscore-dangle
                      key={historyItem.timestamp}
                      {...historyItem}
                    />
                  ))
                ) : (
                  <>
                    <Spinner
                      animation="grow"
                      role="status"
                      className={style.spinner}
                    />
                    <Spinner
                      animation="grow"
                      role="status"
                      className={style.spinner}
                    />
                  </>
                )}
              </div>
            </div>
          </TabPane>
        </TabContent>
      </TabContainer>
    </div>
  )
}

TabsBlock.defaultProps = {
  className: '',
}

TabsBlock.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  owner: PropTypes.object.isRequired,
  creator: PropTypes.object.isRequired,
  history: PropTypes.array.isRequired,
}

export default TabsBlock
