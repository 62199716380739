import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Container } from 'react-bootstrap'
import classNames from 'classnames'
import { useMediaQuery } from 'react-responsive'
import { Link } from 'react-router-dom'

import * as style from './NavMenuMobile.module.scss'
import BtnMenu from '../BtnMenu'
import { MENU_ITEMS } from './constants'
import RouteURL from '~routes'
import useAuth from '~hooks/useAuth'

const NavMenuMobile = (props) => {
  const { className, onClickConnectWallet, ...rest } = props

  const { isLoggedIn } = useAuth()

  const [isMenuVisible, setIsMenuVisible] = useState(false)

  const hideMenu = () => setIsMenuVisible(false)
  const toggleMenu = () => setIsMenuVisible((prevState) => !prevState)

  const isBuMd = useMediaQuery({
    query: '(min-width: 640px)',
  })

  useEffect(() => {
    if (isBuMd) hideMenu()
  }, [isBuMd])

  return (
    <>
      <BtnMenu
        {...rest}
        className={className}
        onClick={toggleMenu}
        isMenuOpen={isMenuVisible}
      />
      <div
        className={classNames(style.navMenuMobile, {
          [style.visible]: isMenuVisible,
        })}
      >
        <Container className={style.container}>
          <ul className={classNames('text-joyride-20', style.listLinks)}>
            {MENU_ITEMS.map((menuItem) => (
              <li key={menuItem.label}>
                <Link
                  to={menuItem.url}
                  className={style.navLink}
                  onClick={hideMenu}
                >
                  {menuItem.label}
                </Link>
              </li>
            ))}
          </ul>
          <div className={classNames('text-joyride-20', style.menuFooter)}>
            {isLoggedIn ? (
              <>
                <Link
                  to={`${RouteURL.PROFILE}1`}
                  className={style.navLink}
                  onClick={hideMenu}
                >
                  My Profile
                </Link>
                <hr />
                <Button
                  as={Link}
                  to={RouteURL.MINT_NFT}
                  variant="neon"
                  className={style.btnFooter}
                  onClick={hideMenu}
                >
                  Create
                </Button>
              </>
            ) : (
              <Button
                variant="neon"
                className={style.btnFooter}
                onClick={() => {
                  hideMenu()
                  onClickConnectWallet()
                }}
              >
                Connect Wallet
              </Button>
            )}
          </div>
        </Container>
      </div>
    </>
  )
}

NavMenuMobile.defaultProps = {
  className: '',
}

NavMenuMobile.propTypes = {
  className: PropTypes.string,
  onClickConnectWallet: PropTypes.func.isRequired,
}

export default NavMenuMobile
