import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Icon from '~components/Icon'

import * as style from './PersonalSocialLinks.module.scss'

const PersonalSocialLinks = (props) => {
  const { className, socialLinks, ...rest } = props

  return (
    <ul {...rest} className={classNames(style.personalSocialLinks, className)}>
      {Object.entries(socialLinks).map(([icon, link]) => (
        <li key={icon}>
          <a href={link}>
            <Icon name={icon} size={16} />
          </a>
        </li>
      ))}
    </ul>
  )
}

PersonalSocialLinks.defaultProps = {
  className: '',
}

PersonalSocialLinks.propTypes = {
  className: PropTypes.string,
  socialLinks: PropTypes.any.isRequired,
}

export default PersonalSocialLinks
