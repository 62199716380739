import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './BtnMenu.module.scss'
import Icon from '../../../Icon'

const BtnMenu = (props) => {
  const { className, isMenuOpen, ...rest } = props

  return (
    // eslint-disable-next-line react/button-has-type
    <button
      {...rest}
      className={classNames(style.btnMenu, className)}
      title="Menu"
      aria-label="open menu"
    >
      <Icon name={isMenuOpen ? 'icon-menu-close' : 'icon-menu'} size={24} />
    </button>
  )
}

BtnMenu.defaultProps = {
  className: '',
  type: 'button',
  isMenuOpen: false,
}

BtnMenu.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  isMenuOpen: PropTypes.bool,
}

export default BtnMenu
