import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button } from 'react-bootstrap'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { DevTool } from '@hookform/devtools'

import * as style from './BlockForm.module.scss'
import InputWithLabel from '~components/InputWithLabel'
import InputSelect from '~components/InputSelect'
import InputImage from '~components/InputImage'
import { MOCK_LEGEND_OPTIONS } from '../mocks'
import { maskNumberValue } from '~utils'
import CardExtraPriceInfo from '../CardExtraPriceInfo'

const BlockFormCreate = (props) => {
  const { className, ...rest } = props

  const methods = useForm()

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
  } = methods

  const [price] = watch(['price'])

  const onSubmit = (formValues) => console.log(formValues)

  return (
    <form
      {...rest}
      className={classNames(style.blockForm, className)}
      onSubmit={handleSubmit(onSubmit)}
    >
      <FormProvider {...methods}>
        <InputImage
          text="JPG, PNG or GIF. Max 100MB."
          dropzoneOptions={{
            accept: 'image/jpeg, image/png, image/gif',
            maxSize: 100 * 1000 * 1000, // 100MB
            maxFiles: 1,
            multiple: false,
          }}
          name="uploadedImage"
          id="inp-upload-file"
          label="Upload file"
          required="Please, submit an image for minting"
          msgFileType="Please, submit a JPG, PNG or GIF file"
          errors={errors}
        />
        <InputWithLabel
          label="Name"
          placeholder="Enter NFT name"
          {...register('name', {
            required: 'Please, specify the NFT name',
          })}
          errors={errors}
        />
        <div>
          <Controller
            name="price"
            control={control}
            rules={{
              required: 'Please, specify the price',
              min: {
                value: 0.000000000000000001,
                message: 'The price is too small',
              },
            }}
            render={({ field: { onChange, ...fieldProps } }) => (
              <InputWithLabel
                {...fieldProps}
                id="inp-nft-price"
                label="Price"
                placeholder="Enter price for this NFT"
                inputMode="decimal"
                errors={errors}
                onChange={(e) => onChange(maskNumberValue(e.target.value))}
                autoComplete="off"
                variant="sol"
              />
            )}
          />
          <CardExtraPriceInfo price={price} serviceFee={2.5} />
        </div>
        <InputSelect
          label="Associated with Legend"
          name="associatedLegend"
          placeholder="Select associated Legend"
          options={MOCK_LEGEND_OPTIONS}
          msgRequired="Please, specify the associated Legend"
          errors={errors}
          required
        />
        <InputWithLabel
          label="Description"
          placeholder="Enter NFT description"
          as="textarea"
          rows="3"
          maxLength="280"
          {...register('description', {
            required: 'Please, specify the NFT description',
          })}
          errors={errors}
        />
        <Button type="submit" variant="purple" className={style.btnCreate}>
          CREATE NFT
        </Button>
      </FormProvider>
      <DevTool control={control} />
    </form>
  )
}

BlockFormCreate.defaultProps = {
  className: '',
}

BlockFormCreate.propTypes = {
  className: PropTypes.string,
}

export default BlockFormCreate
