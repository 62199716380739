import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import * as style from './CardUser.module.scss'
import RouteURL from '~routes'

const CardUser = (props) => {
  const { className, id, name, imgUrl, coverImgUrl, ...rest } = props

  return (
    <Link
      to={`${RouteURL.PROFILE}${id}`}
      {...rest}
      className={classNames(style.cardUser, className)}
    >
      <div className={style.imgAspectRatioContainer}>
        <img src={coverImgUrl} alt="cover" className={style.imgCover} />
      </div>
      <div className={style.infoWrapper}>
        <div className={style.imgAvatarWrapper}>
          <img
            src={imgUrl}
            alt={name}
            className={style.imgAvatar}
            width={96}
            height={96}
          />
        </div>
        <h2 className={classNames('text-joyride-15', style.textName)}>
          {name}
        </h2>
      </div>
    </Link>
  )
}

CardUser.defaultProps = {
  className: '',
}

CardUser.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  imgUrl: PropTypes.string.isRequired,
  coverImgUrl: PropTypes.string.isRequired,
}

export default CardUser
