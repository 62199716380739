import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FormProvider, useForm } from 'react-hook-form'
import { Button } from 'react-bootstrap'
import { DevTool } from '@hookform/devtools'

import * as style from './EditProfile.module.scss'
import InputImage from '~components/InputImage'
import InputWithLabel from '~components/InputWithLabel'
import { RHF_EMAIL_RULE, RHF_URL_RULE } from '~utils'
import useAuth from '~hooks/useAuth'

const EditProfile = (props) => {
  const { className, ...rest } = props

  const { disconnect } = useAuth()

  const methods = useForm()

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = methods

  const onSubmit = (formValues) => console.log(formValues)

  return (
    <section {...rest} className={classNames(style.editProfile, className)}>
      <h1>Edit my profile</h1>
      <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
        <FormProvider {...methods}>
          <InputWithLabel
            label="Name"
            placeholder="Enter your display name"
            {...register('name', {
              required: 'Please, enter your name',
              pattern: {
                value: /^[A-Za-z][A-Za-z.\-\s]{0,30}$/,
                message:
                  'The name may only contain latin letters, "-" and "." characters and spaces. The name should only start with a letter',
              },
            })}
            errors={errors}
          />
          <InputWithLabel
            label="Receive email notifications"
            placeholder="Enter email to receive email notifications"
            {...register('email', RHF_EMAIL_RULE)}
            errors={errors}
          />
          <InputImage
            text={
              <>
                Recommended size 1000x1000.
                <br />
                JPG, PNG or GIF. Max 10 MB.
              </>
            }
            dropzoneOptions={{
              accept: 'image/jpeg, image/png, image/gif',
              maxSize: 10 * 1000 * 1000, // 10MB
              maxFiles: 1,
              multiple: false,
            }}
            name="avatarImg"
            id="inp-avatar"
            label="Avatar"
            msgFileType="Please, submit a JPG, PNG or GIF file"
            errors={errors}
          />
          <InputImage
            text={
              <>
                Recommended size 1500x500.
                <br />
                JPG, PNG or GIF. Max 10 MB.
              </>
            }
            dropzoneOptions={{
              accept: 'image/jpeg, image/png, image/gif',
              maxSize: 10 * 1000 * 1000, // 10MB
              maxFiles: 1,
              multiple: false,
            }}
            name="coverImg"
            id="inp-cover"
            label="Cover image"
            msgFileType="Please, submit a JPG, PNG or GIF file"
            errors={errors}
          />
          <InputWithLabel
            label="Website"
            placeholder="Enter your website URL"
            {...register('websiteUrl', RHF_URL_RULE)}
            errors={errors}
          />
          <InputWithLabel
            label="Instagram"
            placeholder="Enter your Instagram username"
            {...register('instagramUsername')}
            errors={errors}
          />
          <InputWithLabel
            label="Twitter"
            placeholder="Enter your Twitter username"
            {...register('twitterUsername')}
            errors={errors}
          />
          <InputWithLabel
            label="Facebook"
            placeholder="Enter your Facebook username"
            {...register('facebookUsername')}
            errors={errors}
          />
          <InputWithLabel
            label="LinkedIn"
            placeholder="Enter your LinkedIn username"
            {...register('LinkedInUsername')}
            errors={errors}
          />
          <Button type="submit" variant="purple" className={style.btnSave}>
            Save
          </Button>
        </FormProvider>
        <hr />
        <button
          type="button"
          className={classNames('text-joyride-13', style.btnDisconnect)}
          onClick={disconnect}
        >
          disconnect
        </button>
        <DevTool control={control} />
      </form>
    </section>
  )
}

EditProfile.defaultProps = {
  className: '',
}

EditProfile.propTypes = {
  className: PropTypes.string,
}

export default EditProfile
