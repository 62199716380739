import React from 'react'
import { Container } from 'react-bootstrap'
import classNames from 'classnames'

import SEO from '~components/SEO'
import CardAthlete from '~components/CardAthlete'
import Paginator from '~components/Paginator'

import { MOCK_LEGENDS } from './mocks'

import * as style from './Legends.module.scss'

/**
 * Legends page
 */
const Legends = () => {
  return (
    <Container>
      <SEO title="All LEGENDS" />
      <section className={style.legends}>
        <h1>All LEGENDS</h1>
        <div className={classNames('grid-legends', style.items)}>
          {MOCK_LEGENDS.map((athleteData) => (
            <CardAthlete key={athleteData.id} {...athleteData} />
          ))}
        </div>
        <Paginator pageCount={20} />
      </section>
    </Container>
  )
}

export default Legends
