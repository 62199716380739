import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Overlay, Popover } from 'react-bootstrap'
import classNames from 'classnames'

import * as style from './DropdownPriceFilter.module.scss'
import DropdownToggle from '../DropdownToggle'
import InputWithLabel from '~components/InputWithLabel'
import { maskNumberValue } from '~utils'

const DropdownPriceFilter = (props) => {
  const { className, minPrice, maxPrice, setMinPrice, setMaxPrice, ...rest } =
    props

  const [isOverlayVisible, setIsOverlayVisible] = useState(false)
  const showOverlay = () => setIsOverlayVisible(true)
  const hideOverlay = () => setIsOverlayVisible(false)

  const toggleRef = useRef()

  const [minPriceLocal, setMinPriceLocal] = useState()
  const [maxPriceLocal, setMaxPriceLocal] = useState()
  const [error, setError] = useState('')

  const applyPrices = () => {
    if (
      minPriceLocal &&
      maxPriceLocal &&
      parseFloat(minPriceLocal) > parseFloat(maxPriceLocal)
    ) {
      setError('The maximum price should be higher than the minimum price')
      return
    }

    setMinPrice(minPriceLocal !== '' ? minPriceLocal : undefined)
    setMaxPrice(maxPriceLocal !== '' ? maxPriceLocal : undefined)
    setError('')
    hideOverlay()
  }

  useEffect(() => {
    if (minPrice !== minPriceLocal) setMinPriceLocal(minPrice)
    if (maxPrice !== maxPriceLocal) setMaxPriceLocal(maxPrice)
    setError('')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [minPrice, maxPrice, isOverlayVisible])

  return (
    <>
      <DropdownToggle
        {...rest}
        text="Price"
        ref={toggleRef}
        onToggle={showOverlay}
        isExpanded={isOverlayVisible}
      />
      <Overlay
        target={toggleRef.current}
        placement="bottom-start"
        show={isOverlayVisible}
        onHide={hideOverlay}
        rootClose
        trigger="click"
        popperConfig={{
          modifiers: [{ name: 'offset', options: { offset: [0, 8] } }],
        }}
      >
        {({ placement, arrowProps, show: _show, popper, ...overlayProps }) => (
          <Popover
            id="search-popover"
            className={style.popover}
            {...overlayProps}
          >
            <div className={style.inputGrid}>
              <InputWithLabel
                placeholder="Min"
                inputMode="decimal"
                value={minPriceLocal}
                onChange={(e) =>
                  setMinPriceLocal(maskNumberValue(e.target.value))
                }
              />
              <span>to</span>
              <InputWithLabel
                placeholder="Max"
                inputMode="decimal"
                value={maxPriceLocal}
                onChange={(e) =>
                  setMaxPriceLocal(maskNumberValue(e.target.value))
                }
              />
            </div>
            {error && (
              <div
                className={classNames(
                  'text-syne-13-semi-bold',
                  style.textError
                )}
              >
                {error}
              </div>
            )}
            <Button
              variant="purple"
              size="sm"
              className={style.btnApply}
              onClick={applyPrices}
            >
              Apply
            </Button>
          </Popover>
        )}
      </Overlay>
    </>
  )
}

DropdownPriceFilter.defaultProps = {
  className: '',
  minPrice: undefined,
  maxPrice: undefined,
}

DropdownPriceFilter.propTypes = {
  className: PropTypes.string,
  minPrice: PropTypes.any,
  maxPrice: PropTypes.any,
  setMinPrice: PropTypes.func.isRequired,
  setMaxPrice: PropTypes.func.isRequired,
}

export default DropdownPriceFilter
