import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import * as style from './InputSearchExpandable.module.scss'
import InputWithLabel from '~components/InputWithLabel'
import RouteURL from '~routes'

const InputSearchExpandable = (props) => {
  const { className, value, setValue, ...rest } = props

  const { pathname } = useLocation()

  const [keepExpanded, setKeepExpanded] = useState(false)

  const isSearchPage = pathname.startsWith(RouteURL.SEARCH.slice(0, -1))

  useEffect(() => {
    if (isSearchPage && value) {
      setKeepExpanded(true)
    } else if (keepExpanded) setKeepExpanded(false)
  }, [isSearchPage, keepExpanded, value])

  return (
    <div
      {...rest}
      className={classNames(style.inpSearchExpandableWrapper, className)}
    >
      <InputWithLabel
        variant="search-expandable"
        className={style.inpSearchExpandable}
        classNameFormControl={classNames({
          'form-control--keep-expanded': keepExpanded,
        })}
        classNameInputGroup={classNames({
          'input-group--keep-expanded': keepExpanded,
        })}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={() => (!isSearchPage ? setValue('') : null)}
      />
    </div>
  )
}

InputSearchExpandable.defaultProps = {
  className: '',
  value: '',
  setValue: () => {},
}

InputSearchExpandable.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  setValue: PropTypes.func,
}

export default InputSearchExpandable
