import dayjs from 'dayjs'

import { AuctionHistoryItemType } from '../../utils/enums'

export const MOCK_NFT_DATA = {
  id: 0,
  title: 'Wayne Rooney #5751',
  description:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Leo tellus pretium consectetur morbi diam nullam eu. Sed volutpat pharetra consectetur scelerisque viverra gravida magna enim.',
  imgUrl: `https://picsum.photos/1200/1200?nocache=${Math.random()}`,
  priceSOL: 3.49,
  priceUSDT: 0.86,
  athlete: {
    id: 1,
    name: 'Wayne Rooney',
    imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  owner: {
    id: 1,
    name: 'Mario Choo',
    imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  creator: {
    id: 2,
    name: 'Michael Pink',
    imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
  },
  history: [
    {
      type: AuctionHistoryItemType.MINT,
      user: {
        id: 3,
        name: 'katyperry',
        imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
      },
      timestamp: dayjs().subtract(4, 'd').subtract(6432, 'm').unix(),
    },
    {
      type: AuctionHistoryItemType.PURCHASE,
      user: {
        id: 4,
        name: 'katyperry',
        imgUrl: `https://picsum.photos/96/96?nocache=${Math.random()}`,
      },
      price: 12.4627,
      timestamp: dayjs().subtract(2, 'd').subtract(1111, 'm').unix(),
    },
  ],
}
