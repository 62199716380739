import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Nav } from 'react-bootstrap'
import { toLower } from 'lodash'

import * as style from './Search.module.scss'
import { MOCK_SEARCH_DATA } from './mocks'
import { SearchItemsType } from '~utils/enums'
import CardAthlete from '~components/CardAthlete'
import CardNFT from '~components/CardNFT'
import CardUser from '~components/CardUser'
import Paginator from '~components/Paginator'

const Search = (props) => {
  const { className, ...rest } = props

  const [activeTab, setActiveTab] = useState(SearchItemsType.LEGENDS)

  return (
    <section {...rest} className={classNames(style.search, className)}>
      <Container>
        <h1 className={style.textPageHeading}>
          Search results for{' '}
          <span className={style.textSearchQuery}>Wayne</span>
        </h1>
        <div className={style.navContainerOuter}>
          <div className={style.navScrollContainer}>
            <Nav
              variant="white"
              onSelect={setActiveTab}
              activeKey={activeTab}
              className={style.nav}
            >
              <Nav.Item>
                <Nav.Link as="button" eventKey={SearchItemsType.LEGENDS}>
                  {SearchItemsType.LEGENDS}
                  <small>{MOCK_SEARCH_DATA.legends.count}</small>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as="button" eventKey={SearchItemsType.ITEMS}>
                  {SearchItemsType.ITEMS}
                  <small>{MOCK_SEARCH_DATA.items.count}</small>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as="button" eventKey={SearchItemsType.USERS}>
                  {SearchItemsType.USERS}
                  <small>{MOCK_SEARCH_DATA.users.count}</small>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className={style.blendShadowStart} />
          <div className={style.blendShadowEnd} />
        </div>
        <div className={style.wrapperItems}>
          {activeTab === SearchItemsType.LEGENDS && (
            <div className="grid-legends">
              {MOCK_SEARCH_DATA.legends.results.map((athleteData) => (
                <CardAthlete key={athleteData.id} {...athleteData} />
              ))}
            </div>
          )}
          {activeTab === SearchItemsType.ITEMS && (
            <div className="grid-nfts">
              {MOCK_SEARCH_DATA.items.results.map((nftData) => (
                <CardNFT key={nftData.id} {...nftData} />
              ))}
            </div>
          )}
          {activeTab === SearchItemsType.USERS && (
            <div className="grid-nfts">
              {MOCK_SEARCH_DATA.users.results.map((userData) => (
                <CardUser key={userData.id} {...userData} />
              ))}
            </div>
          )}
        </div>
        {MOCK_SEARCH_DATA[toLower(activeTab)].count > 20 && (
          <Paginator pageCount={20} className={style.paginator} />
        )}
      </Container>
    </section>
  )
}

Search.defaultProps = {
  className: '',
}

Search.propTypes = {
  className: PropTypes.string,
}

export default Search
