import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Container, Button } from 'react-bootstrap'
import RouteURL from '~routes'

import CardAthlete from '~components/CardAthlete'
import BlockNFT from '~components/BlockNFT'
import SEO from '~components/SEO'
import SectionExplore from './components/SectionExplore'

import { MOCK_LEGENDS, MOCK_HERO_NFT } from './mocks'

import * as style from './Homepage.module.scss'

const Homepage = (props) => {
  const { className, ...rest } = props

  return (
    <div className={style.pageWrapper}>
      <Container>
        <div {...rest} className={classNames(style.homepage, className)}>
          <SEO title="Explore" />
          <section>
            <BlockNFT {...MOCK_HERO_NFT} variant="homepage" />
          </section>
          <section className={style.sectionLegends}>
            <div className={style.rowHeading}>
              <h1>Legends</h1>
              <Button
                as={Link}
                to={RouteURL.LEGENDS}
                variant="purple"
                size="sm"
                className={style.btnShowAllDesktop}
              >
                Show all
              </Button>
            </div>
            <div className={style.outerContainer}>
              <div className={style.scrollContainerLegends}>
                <div className={style.rowLegends}>
                  {MOCK_LEGENDS.map((athleteData) => (
                    <CardAthlete key={athleteData.id} {...athleteData} />
                  ))}
                </div>
              </div>
              <div className={style.blendShadowLegendsStart} />
              <div className={style.blendShadowLegendsEnd} />
            </div>
            <Button
              as={Link}
              to={RouteURL.LEGENDS}
              variant="purple"
              size="sm"
              className={style.btnShowAllMobile}
            >
              Show all
            </Button>
          </section>
          <SectionExplore />
        </div>
      </Container>
    </div>
  )
}

Homepage.defaultProps = {
  className: '',
}

Homepage.propTypes = {
  className: PropTypes.string,
}

export default Homepage
