export const MOCK_ATHLETE_DATA = {
  id: 0,
  name: 'MICHAEL BISPING',
  bio: 'Sports analyst, actor, commentator and retired mixed martial artist, who competed in the Middleweight and Light Heavyweight division of the UFC.',
  photo: `https://picsum.photos/512/512?nocache=${Math.random()}`,
  socials: {
    instagram: '#',
    facebook: '#',
    twitter: '#',
    linkedin: '#',
    web: '#',
  },
}

export const MOCK_NFTS = [
  {
    id: 1,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Wayne',
      lastName: 'Rooney',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 5751,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 3.49,
    status: 'on_sale',
  },
  {
    id: 2,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Muhammad',
      lastName: 'Ali',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 8180,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 2.51,
    status: 'on_sale',
  },
  {
    id: 3,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Alexander',
      lastName: 'Ovechkin',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 6370,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 2.39,
    status: 'sold',
  },
  {
    id: 4,
    imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
    athlete: {
      firstName: 'Michael',
      lastName: 'Bisping',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    nftId: 2485,
    owner: {
      username: 'NickB',
      imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
    },
    price: 5.8,
    status: 'sold',
  },
]
