import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link } from 'react-router-dom'
import RouteURL from '~routes'

import * as style from './CardAthlete.module.scss'

const CardAthlete = (props) => {
  const { id, className, imgUrl, firstName, lastName, nftCount, ...rest } =
    props

  return (
    <Link
      {...rest}
      className={classNames(style.cardAthlete, className)}
      to={`${RouteURL.LEGEND}${id}`}
    >
      <span className={style.imgContainerWrapper}>
        <span className={style.imgAspectRatioContainer}>
          <img
            src={imgUrl}
            alt={`${firstName} ${lastName}`}
            className={style.img}
          />
        </span>
      </span>
      <h2 className={classNames('text-joyride-14', style.textName)}>
        {firstName}
        <br />
        {lastName}
      </h2>
      <span className={classNames('text-syne-13-bold', style.textNftCount)}>
        {nftCount} NFT
      </span>
    </Link>
  )
}

CardAthlete.defaultProps = {
  className: '',
  id: null,
}

CardAthlete.propTypes = {
  className: PropTypes.string,
  imgUrl: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  nftCount: PropTypes.number.isRequired,
  id: PropTypes.any,
}

export default CardAthlete
