export const MOCK_LEGEND_OPTIONS = [
  { label: 'Michael Bisping', value: 1 },
  { label: 'Giorgio Chiellini', value: 2 },
  { label: 'Terence Crawford', value: 3 },
  { label: 'Charlie Edwards', value: 4 },
  { label: 'Chuck Liddell', value: 5 },
]

export const MOCK_NETWORK_OPTIONS = [
  { label: 'Ropsten', value: 3 },
  { label: 'Kovan', value: 42 },
  { label: 'Rinkeby', value: 4 },
  { label: 'Goerli', value: 5 },
]
