import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { round } from 'lodash'

import * as style from './CardExtraPriceInfo.module.scss'

const CardExtraPriceInfo = (props) => {
  const { className, serviceFee, price, ...rest } = props

  const finalPrice = parseFloat(price?.toString() || 0) * (1 - serviceFee / 100)

  return (
    <div
      {...rest}
      className={classNames(
        'text-syne-13-semi-bold',
        style.cardExtraPriceInfo,
        className
      )}
    >
      <span>
        Service fee <span className={style.textPrimary}>{serviceFee}%</span>
      </span>
      <span>
        You will receive{' '}
        <span className={style.textPrimary}>{round(finalPrice, 4)} SOL</span>
      </span>
    </div>
  )
}

CardExtraPriceInfo.defaultProps = {
  className: '',
  price: 0,
}

CardExtraPriceInfo.propTypes = {
  className: PropTypes.string,
  serviceFee: PropTypes.number.isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default CardExtraPriceInfo
