import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Link, useLocation } from 'react-router-dom'

import * as style from './LinkAvatar.module.scss'
import RouteURL from '~routes'

const LinkAvatar = (props) => {
  const { className, ...rest } = props

  const userId = 1

  const { pathname } = useLocation()

  const isOwnProfile = pathname === `${RouteURL.PROFILE}${userId}`

  return (
    <Link
      {...rest}
      className={classNames(
        style.linkAvatar,
        { [style.activePage]: isOwnProfile },
        className
      )}
      to={`${RouteURL.PROFILE}${userId}`}
    >
      <div className={style.backgroundDark}>
        <img
          src={`https://picsum.photos/80/80?nocache=${Math.random()}`}
          alt="Avatar"
          className={style.imgAvatar}
          width={40}
          height={40}
        />
      </div>
    </Link>
  )
}

LinkAvatar.defaultProps = {
  className: '',
}

LinkAvatar.propTypes = {
  className: PropTypes.string,
}

export default LinkAvatar
