export const CONTACTS = [
  {
    pic: 'email',
    text: 'info@blockasset.co',
    link: 'mailto:info@blockasset.co',
  },
  {
    pic: 'telegram',
    text: 't.me/blockassetofficial',
    link: 'https://t.me/blockassetofficial',
  },
]

export const LINKS = [
  {
    name: 'Terms & Conditions',
    link: '#',
  },
  {
    name: 'Privacy Policy',
    link: '#',
  },
]
