import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import * as style from './DropdownToggle.module.scss'
import Icon from '~components/Icon'

const DropdownToggle = React.forwardRef((props, ref) => {
  const { className, text, isExpanded, onToggle, ...rest } = props

  return (
    <button
      {...rest}
      className={classNames(
        'text-joyride-10',
        style.dropdownToggle,
        { [style.active]: isExpanded },
        className
      )}
      type="button"
      onClick={onToggle}
      ref={ref}
    >
      <div className={style.text}>{text}</div>
      <Icon name="icon-chevron-down" size={12} className={style.icon} />
    </button>
  )
})

DropdownToggle.defaultProps = {
  className: '',
  isExpanded: false,
}

DropdownToggle.propTypes = {
  className: PropTypes.string,
  isExpanded: PropTypes.bool,
  text: PropTypes.string.isRequired,
  onToggle: PropTypes.func.isRequired,
}

export default DropdownToggle
