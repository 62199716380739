// extracted by mini-css-extract-plugin
export var footer = "style_footer__2IByV";
export var footerInner = "style_footer_inner__NVN0Z";
export var footerTop = "style_footer_top__bYYau";
export var navMenu = "style_navMenu__0+JhS";
export var footerBox = "style_footerBox__h0F6s";
export var footerDescr = "style_footer_descr__CLQ9V";
export var footerTitle = "style_footer_title__XTiLJ";
export var footerForm = "style_footer_form__3JB+x";
export var footerContacts = "style_footer_contacts__fay9m";
export var footerContactsLink = "style_footer_contacts_link__j9d4o";
export var footerField = "style_footer_field__tut1W";
export var footerBtn = "style_footer_btn__ul4C8";
export var footerBottom = "style_footer_bottom__Jls2O";
export var footerNav = "style_footer_nav__XjFRV";
export var footerNavItem = "style_footer_nav_item__9I+DT";
export var footerNavLink = "style_footer_nav_link__WPGHl";
export var rotate = "style_rotate__6gi+V";
export var dotty = "style_dotty__hnIJG";