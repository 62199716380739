import React, { useEffect } from 'react'
import { Routes, Route, useLocation, Navigate } from 'react-router-dom'

import Layout from './components/Layout'

import './styles/app.scss'
import RouteURL from '~routes'
import NFT from '~containers/NFT'
import Homepage from '~containers/Homepage'
import Legends from '~containers/Legends'
import Legend from '~containers/Legend'
import MintNFT from '~containers/MintNFT'
import EditProfile from '~containers/EditProfile'
import Profile from '~containers/Profile'
import Search from '~containers/Search'

const App = () => {
  const { pathname } = useLocation()

  // Restore scroll on location change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Layout>
      <Routes>
        <Route path={RouteURL.HOMEPAGE} exact element={<Homepage />} />
        <Route path={`${RouteURL.NFT}:id`} exact element={<NFT />} />
        <Route path={RouteURL.LEGENDS} exact element={<Legends />} />
        <Route path={`${RouteURL.LEGEND}:id`} exact element={<Legend />} />
        <Route path={RouteURL.MINT_NFT} exact element={<MintNFT />} />
        <Route path={RouteURL.EDIT_PROFILE} exact element={<EditProfile />} />
        <Route path={`${RouteURL.PROFILE}:id`} exact element={<Profile />} />
        <Route path={RouteURL.SEARCH} exact element={<Search />} />
        <Route path="*" element={<Navigate to={RouteURL.HOMEPAGE} replace />} />
      </Routes>
    </Layout>
  )
}

export default App
