export const MOCK_SEARCH_DATA = {
  legends: {
    count: 28,
    results: [
      {
        id: 1,
        firstName: 'Michael',
        lastName: 'Bisping',
        nftCount: 743,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 2,
        firstName: 'GIORGIO',
        lastName: 'CHIELLINI',
        nftCount: 345,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 3,
        firstName: 'TERENCE',
        lastName: 'CRAWFORD',
        nftCount: 389,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 4,
        firstName: 'CHARLIE',
        lastName: 'EDWARDS',
        nftCount: 481,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 5,
        firstName: 'CHUCK',
        lastName: 'LIDDELL',
        nftCount: 104,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 6,
        firstName: 'Michael',
        lastName: 'Bisping',
        nftCount: 425,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 7,
        firstName: 'GIORGIO',
        lastName: 'CHIELLINI',
        nftCount: 345,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 8,
        firstName: 'TERENCE',
        lastName: 'CRAWFORD',
        nftCount: 453,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 9,
        firstName: 'CHARLIE',
        lastName: 'EDWARDS',
        nftCount: 528,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 10,
        firstName: 'CHUCK',
        lastName: 'LIDDELL',
        nftCount: 104,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 11,
        firstName: 'Michael',
        lastName: 'Bisping',
        nftCount: 743,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 12,
        firstName: 'GIORGIO',
        lastName: 'CHIELLINI',
        nftCount: 345,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 13,
        firstName: 'TERENCE',
        lastName: 'CRAWFORD',
        nftCount: 789,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 14,
        firstName: 'CHARLIE',
        lastName: 'EDWARDS',
        nftCount: 145,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
      {
        id: 15,
        firstName: 'CHUCK',
        lastName: 'LIDDELL',
        nftCount: 453,
        imgUrl: `https://picsum.photos/260/260?nocache=${Math.random()}`,
      },
    ],
  },
  items: {
    count: 120,
    results: [
      {
        id: 1,
        imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
        athlete: {
          firstName: 'Wayne',
          lastName: 'Rooney',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        nftId: 5751,
        owner: {
          username: 'NickB',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        price: 3.49,
        status: 'on_sale',
      },
      {
        id: 2,
        imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
        athlete: {
          firstName: 'Muhammad',
          lastName: 'Ali',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        nftId: 8180,
        owner: {
          username: 'NickB',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        price: 2.51,
        status: 'on_sale',
      },
      {
        id: 3,
        imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
        athlete: {
          firstName: 'Alexander',
          lastName: 'Ovechkin',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        nftId: 6370,
        owner: {
          username: 'NickB',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        price: 2.39,
        status: 'sold',
      },
      {
        id: 4,
        imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
        athlete: {
          firstName: 'Michael',
          lastName: 'Bisping',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        nftId: 2485,
        owner: {
          username: 'NickB',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        price: 5.8,
        status: 'sold',
      },
      {
        id: 5,
        imgUrl: `https://picsum.photos/540/540?nocache=${Math.random()}`,
        athlete: {
          firstName: 'Michael',
          lastName: 'Bisping',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        nftId: 2485,
        owner: {
          username: 'NickB',
          imgUrl: `https://picsum.photos/48/48?nocache=${Math.random()}`,
        },
        price: 5.8,
        status: 'owned',
      },
    ],
  },
  users: {
    count: 12,
    results: [
      {
        id: 1,
        name: 'Wayne Ronalds',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
      {
        id: 2,
        name: 'Wayne D',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
      {
        id: 3,
        name: 'Wayne_Wayne',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
      {
        id: 4,
        name: 'Wayne Ronalds',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
      {
        id: 5,
        name: 'Wayne Ronalds',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
      {
        id: 6,
        name: 'Wayne Ronalds',
        imgUrl: `https://picsum.photos/192/192?nocache=${Math.random()}`,
        coverImgUrl: `https://picsum.photos/540/240?nocache=${Math.random()}`,
      },
    ],
  },
}
