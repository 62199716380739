// extracted by mini-css-extract-plugin
export var cardNft = "CardNFT_cardNft__C8kNr";
export var btnBuy = "CardNFT_btnBuy__Q5Txg";
export var imgAspectRatioContainer = "CardNFT_imgAspectRatioContainer__wtcHI";
export var imgOverlay = "CardNFT_imgOverlay__gyLnG";
export var imgOwner = "CardNFT_imgOwner__RjuFS";
export var badgeNftId = "CardNFT_badgeNftId__Yh7C6";
export var imgNft = "CardNFT_imgNft__cC22h";
export var gridAthlete = "CardNFT_gridAthlete__hrnHx";
export var textAthleteName = "CardNFT_textAthleteName__hgGaf";
export var imgAthlete = "CardNFT_imgAthlete__jfJMX";
export var textBuyNow = "CardNFT_textBuyNow__vWfd0";
export var textPrice = "CardNFT_textPrice__TAGnf";
export var rotate = "CardNFT_rotate__ytsoS";
export var dotty = "CardNFT_dotty__mD0sW";